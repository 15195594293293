import { configureStore } from '@reduxjs/toolkit'
import authSlice, { Auth } from './auth/auth_slice'
import currentContestSlice from './current_contest/current_contest_slice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    currentContest: currentContestSlice,
  },
})

export interface ReduxState {
  auth: Auth
}
