import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentContest: "0",
  currentContestName: "",
};

export const currentContestSlice = createSlice({
  name: "currentContest",
  initialState: initialState,
  reducers: {
    setCurrentContest: (state, action) => {
      state.currentContest = action.payload.id;
      state.currentContestName = action.payload.name;
    },
    crlearCurrentContest: (state) => {
      state.currentContest = initialState.currentContest;
    },
  },
});

export const { setCurrentContest, crlearCurrentContest } =
  currentContestSlice.actions;

export default currentContestSlice.reducer;
