import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'

interface PropTypes {
  show: boolean
}

export default function Loading({ show }: PropTypes) {
  return (
    <Dialog open={show}>
      <DialogTitle>Cargando...</DialogTitle>
      <DialogContent>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  )
}
