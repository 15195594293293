import { decode, JwtPayload } from 'jsonwebtoken'

interface JwtPayloadType extends JwtPayload {
  id: string
}

export function loadTokenFromLocalStorage<AuthType>(
  initialState: AuthType,
): AuthType {
  const authToken: string | null = localStorage.getItem('auth_token')
  if (authToken != null) {
    return decodeToken<AuthType>(initialState, authToken)
  } else {
    return initialState
  }
}

export function decodeToken<AuthType>(
  initialState: AuthType,
  authToken: string,
): AuthType {
  const decoded = decode(authToken) as JwtPayloadType
  let initialLogedState: AuthType
  initialLogedState = {
    ...initialState,
    token: authToken,
    expiration: decoded.exp,
    loading: false,
    isAuthenticated: true,
    iduser: decoded.id,
  }
  return initialLogedState
}
