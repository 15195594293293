import { ReduxState } from '../../store/index'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import routes from '@utilities/routes/index.json'

interface PropTypes {
  children: JSX.Element
}

export default function PrivateRoute({ children }: PropTypes) {
  const isAuthenticated = useSelector(
    (state: ReduxState) => state.auth.isAuthenticated,
  )
  let location = useLocation()

  if (isAuthenticated) {
    return children
  } else {
    return <Navigate to={routes.login} state={{ from: location }} replace />
  }
}
