import createTheme, {
  ThemeOptions,
  Theme,
} from '@mui/material/styles/createTheme'
import { Mixins } from '@mui/material/styles/createMixins'

export interface IMixins extends Mixins {
  drawer: {
    minWidth: string
    closedMinWidth: string
  }
  header: {
    height: string
    padding: string
  }
}
export interface IThemeOptions extends ThemeOptions {
  mixins: IMixins
}
export interface ITheme extends Theme {
  mixins: IMixins
}

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    primary: {
      main: '#111111',
      light: '#222222',
      dark: '#000000',
    },
    secondary: {
      main: '#09D9EC',
      light: '#26E6F7',
      dark: '#07B5C5',
    },
  },
  mixins: {
    drawer: {
      minWidth: '20rem',
      closedMinWidth: '0px',
    },
    header: {
      height: '5rem',
      padding: '0.5rem',
    },
    toolbar: {
      minHeight: '5rem',
    },
  },
} as IThemeOptions) as ITheme

export default theme
