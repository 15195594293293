import { createSlice } from '@reduxjs/toolkit'
import { decodeToken, loadTokenFromLocalStorage } from '@utilities/decode_token'

export interface Auth {
  token: string
  expiration: string
  loading: boolean
  isAuthenticated: boolean
  username: string
  role: number
  iduser: number
}

const initialState: Auth = {
  token: '',
  expiration: '',
  loading: false,
  isAuthenticated: false,
  username: '',
  role: 0,
  iduser: 0,
}
let initialLogedState: Auth = loadTokenFromLocalStorage<Auth>(initialState)

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialLogedState || initialState,
  reducers: {
    loginStart: (state) => {
      state.loading = true
    },
    loginSuccess: (state, action) => {
      localStorage.setItem('auth_token', action.payload.jwt)
      state.loading = false
      state.token = action.payload.jwt
      state.expiration = action.payload.expiration
      state.isAuthenticated = true
      let decoded = decodeToken(initialState, action.payload.jwt)
      state.iduser = decoded.iduser
    },
    loginFail: (state) => {
      state.loading = false
      state.token = initialState.token
      state.expiration = initialState.expiration
      state.loading = initialState.loading
      state.isAuthenticated = initialState.isAuthenticated
      state.iduser = initialState.iduser
    },
    logout: (state) => {
      state.loading = false
      state.token = initialState.token
      state.expiration = initialState.expiration
      state.loading = initialState.loading
      state.isAuthenticated = initialState.isAuthenticated
      state.username = initialState.username
      state.role = initialState.role
      state.iduser = initialState.iduser
      localStorage.clear()
    },
  },
})

export const { loginStart, loginSuccess, loginFail, logout } = authSlice.actions

export default authSlice.reducer
