import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import PrivateRoute from '@components/auth/private_route'
import routes from '@utilities/routes/index.json'
import { Suspense, lazy } from 'react'
import Loading from '@components/loading/loading'

const MainLayout = lazy(() => import('@layouts/main/main_layout'))
const LoginLayout = lazy(() => import('@layouts/login/login_layout'))
const RegisterLayout = lazy(() => import('@layouts/login/register_layout'))
const Login = lazy(() => import('@pages/login/login'))
const ResetPassword = lazy(() => import('@pages/reset_password/reset_password'))
const ChangePassword = lazy(() =>
  import('@pages/change_password/change_password'),
)

const Register = lazy(() => import('@pages/register/register'))
const Home = lazy(() => import('@pages/home/home'))
const ContestList = lazy(() => import('@pages/contest/contest_list'))
const CategoryList = lazy(() => import('@pages/contest/category_list'))
const ImageHistory = lazy(() => import('@pages/image_history/image_history'))
const Payments = lazy(() => import('@pages/payments/payments'))

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routes.login}
          element={
            <Suspense fallback={<Loading show />}>
              <LoginLayout>
                <Login />
              </LoginLayout>
            </Suspense>
          }
        ></Route>
        <Route
          path={routes.reset_password}
          element={
            <Suspense fallback={<Loading show />}>
              <LoginLayout>
                <ResetPassword />
              </LoginLayout>
            </Suspense>
          }
        ></Route>
        <Route
          path={`${routes.change_password}/:token`}
          element={
            <Suspense fallback={<Loading show />}>
              <LoginLayout>
                <ChangePassword />
              </LoginLayout>
            </Suspense>
          }
        ></Route>
        <Route
          path={routes.register}
          element={
            <Suspense fallback={<Loading show />}>
              <RegisterLayout>
                <Register />
              </RegisterLayout>
            </Suspense>
          }
        ></Route>

        <Route
          path={routes.contests}
          element={
            <PrivateRoute>
              <Suspense fallback={<Loading show />}>
                <MainLayout>
                  <CategoryList />
                </MainLayout>
              </Suspense>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={routes.history}
          element={
            <PrivateRoute>
              <Suspense fallback={<Loading show />}>
                <MainLayout>
                  <ImageHistory />
                </MainLayout>
              </Suspense>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={routes.payments}
          element={
            <PrivateRoute>
              <Suspense fallback={<Loading show />}>
                <MainLayout>
                  <Payments />
                </MainLayout>
              </Suspense>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={routes.profile}
          element={
            <PrivateRoute>
              <Suspense fallback={<Loading show />}>
                <MainLayout>
                  <Register />
                </MainLayout>
              </Suspense>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path={routes.root}
          element={
            <PrivateRoute>
              <Suspense fallback={<Loading show />}>
                <MainLayout>
                  <Home />
                </MainLayout>
              </Suspense>
            </PrivateRoute>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
